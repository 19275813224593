import { Outlet } from 'react-router-dom';
import Footer from './components/Header/Footer';
import Header from "./components/Header/Header";

function Layout({ children }) {
   return (
    <>
        <Header />
        <Outlet />
        <Footer/>
        </>
  );
}

export default Layout;