import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: "",
      open: false,
      position: "left",
      noOverlay: true,
      matchData: "",
      loader: false,
      admin_text: "",
      username: "",
      customSuperAdmin: false,
      permission_data: []
    };

  }




 
  componentDidMount() {
  }
  render() {
    return (
         <nav className="navbar fixed-top navbar-expand-md navbar-light top-menu">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img src="/img/logo.png" style={{width:"60px"}}/>
                </a>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a href="/" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item">
                            <a href="/#about" className="nav-link">About</a>
                        </li>
                        <li className="nav-item">
                            <a href="/#result" className="nav-link">Result</a>
                        </li>
                        <li className="nav-item">
                            <a href="/#pricing" className="nav-link">TimeTable</a>
                        </li>
                        <li className="nav-item">
                            <a href="/#ratechart" className="nav-link">Rate Chart</a>
                        </li>
                        <li className="nav-item">
                            <a href="/#how-it-works" className="nav-link">How to Play</a>
                        </li>
                        <li className="nav-item">
                            <a href="#contact" className="nav-link">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
     );
  }
}
export default Header;
