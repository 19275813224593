import axios from 'axios';
import React, { Component } from 'react';
import Url_Prod from '../../config';
import withRouter from '../../withRouter';


const getQueryParams = () => window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {});

class Thankyou extends Component {

	constructor(props) {
		super(props);
		this.state = { isloading:false,url:"",content:"",data:getQueryParams()};
	} 
	componentWillMount() {
		this.getpagecontent();
	}
	setstatedata =(new_array)=>
	{
		this.setState({content:new_array});

	}
	getpagecontent = async () => {
		
		this.setState({isloading:true})
		await axios.get(Url_Prod.baseUrl + 'getthankyoudata/'+this.state.data.client_txn_id).then((response) =>{
			if(response.status && response!==undefined)
			var resp = response.data;
			console.log(resp);
			this.setstatedata(resp.result	)
		})
		.catch(function (error) {
			// handle error
			console.log(error);
		})
		.then(function () {
			// always executed
		});
	}
	preloader = () => {
		return <div className="preloader-wrap" style="display: none;">
			<div className="preloader">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	}
	render() {
		let {content} =this.state;
		return (
			<>

<section class="top-banner-area"> 
            <div class="container"> 
                <div class="row"> 
                    <div class="col-lg-6"> 
                        <h2 class="banner-title">{content}</h2>   
                    </div>
                    <div class="col-lg-6"> 
                        <ol class="breadcrumb">
                            <li><a href="/">Home</a></li>
                            <li class="active">{content}</li>
                        </ol>   
                    </div>
                </div>
            </div>
        </section>
				<section id="result" className="awsome-features">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<table className='table table-bordered'>
									<tr>
										<td>Transaction Id : </td>
										<td>{this.state.data.client_txn_id}</td>
									</tr>
									<tr>
										<td>Status : </td>
										<td>{content}</td>
									</tr>
								</table>
							</div>
						</div>

						<div className="row">
						
							
						</div>

					</div>
				</section>

			</>
		);
	}
}

export default withRouter(Thankyou);
