/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const GameResultListItem = props => {
    const {Item,dateformat} = props;
   
             return (
                <div class="col-md-12 col-lg-3">
                <div class="single-feature  wow fadeInUp" data-wow-delay=".4s">
                    <h4>{Item.number!==undefined?Item.number:Item.pattern}</h4>
                    <h4 style={{ fontSize: "17px" }}>{Item.mg_number!==undefined?Item.title:Item.title}</h4>
                    <p>{Item.number!==undefined?dateformat(Item.result_time):dateformat(Item.result_time)}</p>
                </div>
            </div>
            )
       
}

export default React.memo(GameResultListItem)