import axios from 'axios';
import React, { Component } from 'react';
import Url_Prod from '../../config';
import withRouter from '../../withRouter';




class Chart extends Component {

	constructor(props) {
		super(props);
		this.state = { isloading:false,url:"",content:"",housedetail:{},dates:[]};
		
	}
	componentWillMount() {
		this.getpagecontent();
	}
	setstatedata =(new_array,h)=>
	{
		this.setState({dates:new_array,housedetail:h});

	}
	getpagecontent = async () => {
		
		this.setState({isloading:true})
		await axios.get(Url_Prod.baseUrl + 'get-chart/'+this.props.params.slug).then((response) =>{
			if(response.status && response!==undefined)
			var resp = response.data;
			if(resp.dates!==null)
			this.setstatedata(resp.dates,resp.house_detail)
		})
		.catch(function (error) {
			// handle error
			console.log(error);
		})
		.then(function () {
			// always executed
		});
	}
	preloader = () => {
		return <div className="preloader-wrap" style="display: none;">
			<div className="preloader">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	}
	connecthtml = (dates)=>{
		let html =[];
								
		dates.map((Item)=>{
			let hhtml2 =[];
			hhtml2.push(<td style={{whiteSpace:"nowrap"}}>{Item.date}</td>)
			Item.data.map((element) => {
				hhtml2.push(<td>{element}</td>)
			});
			html.push(<tr>{hhtml2}</tr>)

		   })
		   return html;
	}
	render() {
		let {housedetail,dates} =this.state;
		return (
			<>

<section class="top-banner-area"> 
            <div class="container"> 
                <div class="row"> 
                    <div class="col-lg-6"> 
                        <h2 class="banner-title">Chart</h2>   
                    </div>
                    <div class="col-lg-6"> 
                        <ol class="breadcrumb">
                            <li><a href="/">Home</a></li>
                            <li class="active">Chart</li>
                        </ol>   
                    </div>
                </div>
            </div>
        </section>
				<section id="result" className="awsome-features">
					<div className="container">
					

						<div className="row">
							<div className='col-lg-12'>
								<div className='table-responsive'>
							<table className='table table-bordered'>
								<tr>
								<th>
									Date
								</th>
								{housedetail && housedetail.length>0 ?housedetail.map((Item)=><th style={{whiteSpace:"nowrap"}}>{Item}</th>):""}
								</tr>
								<tbody>
									{this.connecthtml(dates)}
								</tbody>
							</table>
							</div>
							</div>
						</div>

					</div>
				</section>

			</>
		);
	}
}

export default withRouter(Chart);
