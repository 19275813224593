import axios from "axios";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Url_Prod from "../../config";

class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {isloading:false,contact:{}};
  }


  componentDidMount() {
    this.getusercontent();
}
setstatedata =(contact)=>
{
    this.setState({isloading:false,contact:contact});
}
getusercontent = async () => {

    this.setState({isloading:true})
    await axios.get(Url_Prod.baseUrl + 'gethomedata').then((response) =>{
        if(response.status && response!==undefined)
        var resp = response.data;
        this.setstatedata(resp.result.contact)
    })
    .catch(function (error) {
        // handle error
        console.log(error);
    })
    .then(function () {
        // always executed
    });
}
  render() {
      let {contact} = this.state;
    return (
        <>
        <section id="contact" className="contact-section gray-bg cs-pt-130">
					<div className="wave-bg-section-top-one"></div>

					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title wow fadeInUp" data-wow-delay=".3s">
									<h2>Get in Touch</h2>
									<p>Talk with an advisor to create a customized inbound marketing program that blends proven strategy with tactical instruction and guarantees results.</p>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-4 col-lg-4">
								<div className="single-contact-info wow fadeInUp" data-wow-delay=".4s">
									<i className="icofont-location-pin"></i>
									<h3>Office Address:</h3>
									<p>{contact.address}</p>
								</div>
							</div>

							<div className="col-md-4 col-lg-4">
								<div className="single-contact-info wow fadeInUp" data-wow-delay=".5s">
									<i className="icofont-envelope"></i>
									<h3>E-mail Address:</h3>
									<p><a href={'mailto'+contact.email} className="__cf_email__" data-cfemail="612b08030e21060c00080d4f020e0c">{contact.email}</a></p>
								</div>
							</div>

							<div className="col-md-4 col-lg-4">
								<div className="single-contact-info wow fadeInUp" data-wow-delay=".6s">
									<i className="icofont icofont-phone"></i>
									<h3>Phone Number:</h3>
									<p>{contact.mobile}</p>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-4 col-lg-4">
								<div className="single-contact-info wow fadeInUp" data-wow-delay=".4s">
									<i className="icofont-location-pin"></i>
									<h3>Telegarm:</h3>
									<p><a href={contact.telegram}>{contact.telegram}</a></p>
								</div>
							</div>

							<div className="col-md-4 col-lg-4">
								<div className="single-contact-info wow fadeInUp" data-wow-delay=".5s">
									<i className="icofont-envelope"></i>
									<h3>Whatsapp:</h3>
									<p><a href={contact.whatsapp} className="__cf_email__" data-cfemail="612b08030e21060c00080d4f020e0c">{contact.whatsapp}</a></p>
								</div>
							</div>

							<div className="col-md-4 col-lg-4">
								<div className="single-contact-info wow fadeInUp" data-wow-delay=".6s">
									<i className="icofont icofont-phone"></i>
									<h3>Google Play Store:</h3>
                                    <p><a href={contact.app_url} className="__cf_email__" data-cfemail="612b08030e21060c00080d4f020e0c">{contact.app_url}</a></p>
								</div>
							</div>
						</div>
					</div>
				</section>
        <footer id="footer"> 
            <div className="footer-top">
                <div className="container"> 
                    <div className="row"> 
                        <div className="col-lg-12"> 
                            <div className="subscribe-text">
                                <h3>Subscribe for our Newsletter</h3>
                                <p>Signup to receive email updates on new product announcements or new market available.</p>
                            </div>
                            
                            <div className="subscribe-form">
                                <form className="newsletter-form" data-toggle="validator">
                                    <input type="email" className="form-control" placeholder="Enter your email address" name="EMAIL" required autoComplete="off" />
                                    <button type="submit" className="btn subscribe-btn">
                                        <i className="icofont-paper-plane"></i>
                                    </button>
                                    <div id="validator-newsletter" className="form-result"></div>
                                </form>
                            </div>

                            <div className="quick-links">
                                <a href="/page/privacy-policy">Privacy Policy</a>
                                &nbsp;&nbsp;&nbsp;
                                |&nbsp;&nbsp;&nbsp;
                                <a href="/page/terms-conditions">Terms & Conditions</a>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
                
            <div className="footer-bottom">
                <div className="container"> 
                    <div className="row"> 
                        <div className="col-md-6 col-lg-6"> 
                            <p className="copyright">Copyright © 2022 Raj Guru Star. All Right Reserved</p>
                        </div>
                        <div className="col-md-6 col-lg-6"> 
                            <ul className="social-links"> 
                                <li>Follow us :</li>
                                <li>
                                    <a href={contact.facebook}>
                                        <i className="icofont-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={contact.twitter}>
                                        <i className="icofont-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={contact.youtube}>
                                        <i className="icofont-youtube"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={contact.pinterest}>
                                        <i className="icofont-pinterest"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </>
     );
  }
}
export default Footer;
