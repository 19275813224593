
import React, { useEffect, useState } from "react";
import { Outlet } from 'react-router-dom';
function MainLayout({ children }) {
 
  return (
    <>
      <Outlet />
    </>
  );
}

export default MainLayout;