import axios from 'axios';
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next/lib/src/bootstrap-table';
import Url_Prod from '../../config';
import GameResultList from './components/GameResultList';
import moment from 'moment';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const dateformatClose = (cell, row, value) => {
	let date_ob = new Date(row.close_time);
	return moment(date_ob).format('hh:mm A');
  }
  const dateformatOpen= (cell, row, value) => {
	let date_ob = new Date(row.opentime);
	return moment(date_ob).format('hh:mm A');
  }
  const viewchart= (cell, row, value) => {
	return <a href={"/view-chart/"+row.id}>View Chart</a>;
  }


const columns = [{
	dataField: 'title',
	text: 'Market'
  }, {
	dataField: 'mf_open_time',
	text: 'Open',
	formatter: dateformatOpen
  }, {
	dataField: 'mf_close_time',
	text: 'Close',
	formatter: dateformatClose

  }, {
	dataField: 'mf_image',
	text: 'Results',
	formatter: viewchart

  }];


class Home extends Component {

	constructor(props) {
		super(props);
		this.state = { accessToken: "", balance: 0 ,last_result_four:[],isloading:false,game_data:[]};
		
	}
	componentWillMount() {
		this.getusercontent();
	}
	setstatedata =(new_array,game_data)=>
	{
		this.setState({last_result_four:new_array,isloading:false,game_data:game_data});

	}
	getusercontent = async () => {

		this.setState({isloading:true})
		await axios.get(Url_Prod.baseUrl + 'gethomedata').then((response) =>{
			if(response.status && response!==undefined)
			var resp = response.data;
			var new_array = [...resp.result.last_four,...resp.result.game_data];
			this.setstatedata(new_array,resp.result.game_data)
		})
		.catch(function (error) {
			// handle error
			console.log(error);
		})
		.then(function () {
			// always executed
		});
	}
	preloader = () => {
		return <div className="preloader-wrap" style="display: none;">
			<div className="preloader">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	}
	render() {
		return (
			<>

				<div id="home" className="hompage-slides-wrapper">
					<div className="homepage-slides">
						<div className="single-slider-item gradient-bg wave-bg-one item-img-bg">
							<div className="slide-item-table">
								<div className="slide-item-tablecell">
									<div className="container">
										<div className="row">
											<div className="col-md-12 col-lg-7">
												<div className="slider-text">
													<h1>Download Best Online Matka App!</h1>
													<p>Play Online Matka with Rajguru Star & Win Money Daily! In our App You Can Play Online Game on All India Official Matka Markets and See Live Results Update.</p>

													<div className="slide-button">
														<a href={Url_Prod.apkurl} className="slide-btn-white mr-10">
															<i className="icofont-cloud-download"></i>
															Download app now
														</a>
														<a href="#about" className="slide-btn-bordered">Learn More</a>
													</div>
												</div>
											</div>

											<div className="col-md-12 col-lg-5">
												<div className="welcome-phone">
													<img src="img/app-mockup-1.png" alt="App Mockup image" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<section id="result" className="awsome-features">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title wow fadeInUp" data-wow-delay=".3s">
									<h2>Matka Online Play Result</h2>
									<p>We provide Latest result of listed market.</p>
								</div>
							</div>
						</div>

						<div className="row">
							{!this.state.isloading?
							<GameResultList  new_array={this.state.last_result_four}/>:""
							}
							
						</div>

					</div>
				</section>

				<section id="pricing" className="awsome-features">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title wow fadeInUp" data-wow-delay=".3s">
									<h2>Matka Online Play Result</h2>
									<p>We provide Latest result of listed market.</p>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12 col-lg-12">
							<BootstrapTable keyField='mf_id' data={ this.state.last_result_four } columns={ columns } />
							</div>


						</div>

					</div>
				</section>
				<section id="ratechart" className="app-about-section angle-sp">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title angle-section-title wow fadeInUp" data-wow-delay=".3s">
									<h2>Online Matka Game Play Rates</h2>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-7">
								<div className="app-about-text wow fadeInUp" data-wow-delay=".6s">
									<h3>Rates</h3>

									<ul>
										<li><i className="icofont-verification-check"></i>  SINGLE DIGIT 10 ka 90</li>
										<li><i className="icofont-verification-check"></i>  JODI DIGIT 10 ka 900</li>
										<li><i className="icofont-verification-check"></i> SINGLE PANA 10 ka 1400</li>
										<li><i className="icofont-verification-check"></i> DOUBLE PANA 10 ka 2800</li>
										<li><i className="icofont-verification-check"></i> TRIPLE PANA 10 ka 7000</li>
										<li><i className="icofont-verification-check"></i> HALF SANGAM 10 ka 10000</li>
										<li><i className="icofont-verification-check"></i> FULL SANGAM 10 ka 100000</li>
									</ul>

									<a href={Url_Prod.apkurl} className="default-button">
										<i className="icofont-cloud-download"></i>
										Download App
									</a>
								</div>
							</div>

							<div className="col-lg-5">
								<div className="text-center wow fadeInUp" data-wow-delay=".9s">
									<img src="img/app-about.png" alt="App About" />
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="about" className="app-about-section angle-sp">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title angle-section-title wow fadeInUp" data-wow-delay=".3s">
									<h2>About Our App</h2>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-7">
								<div className="app-about-text wow fadeInUp" data-wow-delay=".6s">
									<h3>Best Mobile App</h3>
									<p>Welcome to Rajguru Star Line, We are Asia’s No 1 Matka app and the First That Provide 24*7 Withdrawal Facility. We offer you a genuinely unique matka app experience. Besides, We also offer global liquidity, best industry pricing with a single click.</p>
									<p>In our App You Can Play Online Game on All India Official Matka Markets and See Live Results Update For Various Matka Markets i.e. Kalyan Matka, Rajdhani Matka, Milan Matka, Supreme Matka, Madhur Matka, Sridevi Matka, Time Bazar Matka, Main Bazar.</p>

									<ul>
										<li><i className="icofont-verification-check"></i> Easy to used</li>
										<li><i className="icofont-verification-check"></i> Easy to Play</li>
										<li><i className="icofont-verification-check"></i> One Click Access</li>
									</ul>

									<a href={Url_Prod.apkurl} className="default-button">
										<i className="icofont-cloud-download"></i>
										Download App
									</a>
								</div>
							</div>

							<div className="col-lg-5">
								<div className="text-center wow fadeInUp" data-wow-delay=".9s">
									<img src="img/app-about.png" alt="App About" />
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="how-it-works" className="how-it-works wave-one-how-it-works gray-bg">
					<div className="wave-bg-section-tb-one"></div>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title wow fadeInUp" data-wow-delay=".3s">
									<h2>How it works?</h2>
									<p>Rajguru Star Line app process in 4 Step</p>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-6">
								<div className="hiw-feature-content">
									<div className="single-hiw-feature wow fadeInUp" data-wow-delay=".3s">
										<i className="icofont-login"></i>
										<h4>Login First</h4>
										<p>Cleck To Sing up for  Register and Enter your Name -Mobile Number -Email I'd -6 Digit Password and Enter Referral Code if so -If not, leave it blank Than Cleck to Register</p>
									</div>

									<div className="single-hiw-feature wow fadeInUp" data-wow-delay=".4s">
										<i className="icofont-test-tube-alt"></i>
										<h4>Recharge Wallet</h4>
										<p>Please recharge Wellet for play Any market select Add Points and Enter rupees then select submit icon you can pay any UPI Application as Phone pe -Paytm-Google Pe- Etc And Enjoy Raj Guru Best Matka Play App.</p>
									</div>

									<div className="single-hiw-feature wow fadeInUp" data-wow-delay=".5s">
										<i className="icofont-chart-histogram-alt"></i>
										<h4>Select Market</h4>
										<p>There are many markets in our Rajguru Star Application select any market as Main Markets-Sridevi -Kalyan Madhur morning- Time -bazar Madhur night-Gali Disawar- Faridabad Ghaziabad and Starline markets.</p>
									</div>

									<div className="single-hiw-feature wow fadeInUp" data-wow-delay=".6 s">
										<i className="icofont-light-bulb"></i>
										<h4>Play Games</h4>
										<p>Dear users you can now play India's All Satta Matka Markets and enjoy Most truste'd our Application RajGuru star online Matka play.</p>
									</div>
								</div>
							</div>

							<div className="col-lg-5 offset-lg-1">
								<div className="video-demo-content wow fadeInUp" data-wow-delay=".9s">
									<img src="img/video-demo.jpg" alt="Video Demo Image" className="img-circle" />
									<div className="play-video-icon">
										<a className="popup-youtube" href="https://www.youtube.com/embed/bAOzT8XAxUY">
											<i className="icofont-play"></i>
											<div className="sonar-wrapper">
												<div className="sonar-emitter">
													<div className="sonar-wave"></div>
												</div>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id="screenshots" className="app-screenshots-section">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title wow fadeInUp" data-wow-delay=".3s">
									<h2>App Screenshots</h2>
									<p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor coli incididunt ut labore Lorem ipsum madolor sit amet, consectetur adipisicing incididunt.</p>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-12">

   <OwlCarousel className='owl-theme' loop margin={10}>
    <div class='item'>
       <img src="img/MockUp1.png" alt="Screenshot Image" />
    </div>
	<div class='item'>
       <img src="img/MockUp2.png" alt="Screenshot Image" />
    </div>
	<div class='item'>
       <img src="img/MockUp3.png" alt="Screenshot Image" />
    </div>
	<div class='item'>
       <img src="img/MockUp4.png" alt="Screenshot Image" />
    </div>
	<div class='item'>
       <img src="img/MockUp6.png" alt="Screenshot Image" />
    </div>
	<div class='item'>
       <img src="img/MockUp9.png" alt="Screenshot Image" />
    </div>
</OwlCarousel>
								
							</div>
						</div>
					</div>

					<div className="modal right screenshots-modal fade" id="screenshot_details">
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<div className="screenshot-details-container">
										<div className="row">
											<div className="col-md-5 col-lg-5">
												<img src="img/1.png" alt="Screenshot Image" />
											</div>

											<div className="col-md-7 col-lg-7">
												<div className="screenshot-details-text">
													<h3 className="title">Screenshots Title</h3>
													<p>Download Online Matka Play app free for ALL Markets as Kalyan,shreedevi, Rajdhani night,milian nights.</p>
													<p>Fast Result watch with Rajguru star Matka app.</p>

													<ul>
														<li>
															<i className="icofont icofont-check"></i>
															High Resolution
														</li>
														<li>
															<i className="icofont icofont-check"></i>
															Unique Design
														</li>
														<li>
															<i className="icofont icofont-check"></i>
															Full Responsive
														</li>
														<li>
															<i className="icofont icofont-check"></i>
															Clean Codes
														</li>
													</ul>

													<p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
													<p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="app-download" className="app-download-section wave-one-download-section">
					<div className="wave-bg-section-tb-one"></div>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title wow fadeInUp" data-wow-delay=".3s">
									<h2>NOW AVAILABLE</h2>
									<p>Download Online Matka Play app free for ALL Markets as Kalyan,shreedevi, Rajdhani night,milian nights.
										Fast Result watch with Rajguru star Matka app.</p>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-12">
								<div className="app-download-content wow fadeInUp" data-wow-delay=".6s">

									<a href={Url_Prod.apkurl} className="download-btn">
										<i className="icofont-brand-android-robot"></i>
										<span>
											Download
											<span className="large-text">APK</span>
										</span>
									</a>

									<a href={Url_Prod.apkurl} className="download-btn">
										<i className="icofont-brand-android-robot"></i>
										<span>
											available on
											<span className="large-text">Play Store</span>
										</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>

				

			</>
		);
	}
}

export default Home;
