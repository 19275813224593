import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import PageNotFound from "./components/PageNotFound";
import Layout from "./Layout";
import MainLayout from "./MainLayout";
import Home from "./components/Home/home";
import Page from "./components/Page/page";
import Chart from "./components/Chart/chart";
import Thankyou from "./components/Thankyou/thankyou";


class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter >
          <Routes>
            <Route element={<MainLayout />}>
              <Route element={<Layout />}>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/thankyou" element={<Thankyou />} />
                <Route exact path="/page/:id" element={<Page />} />
                <Route exact path="/view-chart/:slug" element={<Chart />} />
           
              </Route>
              <Route path='*' element={<PageNotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
