/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React from 'react';
import GameResultListItem from './GameResultListItem';

const dateformat = ( row) => {
    let date_ob = new Date(row);
    return moment(date_ob).format('hh:mm A');
  }

const GameResultList = props => {
    const {new_array} = props;
     return (
        new_array.map((item, index) => {
             return (
                <GameResultListItem Item={item} dateformat={dateformat} />
            )
        })
    )
}

export default React.memo(GameResultList)